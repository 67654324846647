<template>
  <div class="controlsCarousel">
    <div class="controlsCarousel__back" @click="prev()">
      <IconSax type="bold" name="arrow-circle-left" />
    </div>
    <div class="controlsCarousel__next" @click="next()">
      <IconSax type="bold" name="arrow-circle-right" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.controlsCarousel {
  @include flex-row;
  cursor: pointer;
  
  &__back, &__next {
    transition: opacity 0.3s ease;
    ::v-deep .svgCss svg {
      width: 32px;
      height: 32px;
      path {
        fill: var(--controls-prev-next);
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
